define('client/main/user-management/user/route', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route;
  exports.default = Route.extend({
    model: function model(params) {
      var model = params.id === 'new' ? this.store.createRecord('user', { type: 'admin', isActive: true }) : this.store.peekRecord('user', params.id);
      if (!model) {
        this.transitionTo('not-found-redirect');
      }
      return model;
    }
  });
});