define('client/main/reset-password/controller', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      inject = _ember.default.inject,
      isBlank = _ember.default.isBlank;
  exports.default = Controller.extend({ // eslint-disable-line ember/alias-model-in-controller
    fb: inject.service('firebase-app'),
    processing: false,
    actions: {
      reset: function reset() {
        this.set('processing', true);

        var auth = this.get('fb').auth();
        var email = this.get('email');

        if (isBlank(email)) {
          return this.resetFailed(this.i18n.t('error-messages.required', {
            field: 'E-mail'
          }));
        }
        auth.sendPasswordResetEmail(email).then(this.resetSuccess.bind(this), this.resetFailed.bind(this));
      }
    },
    resetSuccess: function resetSuccess() {
      this.set('processing', false);
      this.get('notifications').success(this.i18n.t('notifications.password-reset-success'));
      this.transitionToRoute('login');
    },
    resetFailed: function resetFailed(err) {
      this.set('processing', false);
      this.set('errorMessage', err);
    }
  });
});