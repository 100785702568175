define('client/main/components/file-upload/component', ['exports', 'client/main/components/image-upload/component'], function (exports, _component) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _component.default.extend({
    classNames: ['component-file-upload'],
    skipResize: true
  });
});