define('client/main/event-management/route', ['exports', 'client/main/base/list-page/route'], function (exports, _route) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _route.default.extend({
    init: function init() {
      this._super.apply(this, arguments);
      this.MODEL_NAME = 'event';
      this.ROUTE_NAME = 'event-management';
    }
  });
});