define('client/main/base/list-page/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      RSVP = _ember.default.RSVP;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    model: function model() {
      return RSVP.hash({
        listItems: this.store.findAll(this.MODEL_NAME, { reload: true })
      });
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('MODEL_NAME', this.MODEL_NAME);
      controller.set('ROUTE_NAME', this.ROUTE_NAME);
    }
  });
});