define('client/main/components/i-validated-select/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      _get = _ember.default.get;
  exports.default = Component.extend({
    classNames: ['component-i-validated-select', 'form-group'],
    classNameBindings: ['isInvalid:has-error'],
    displayField: null,
    isDisabled: false,
    isSearchEnabled: false,
    isInvalid: computed('changeset.error', 'property', {
      get: function get() {
        return _get(this.get('changeset.error'), this.get('property'));
      }
    }),

    init: function init() {
      this._super.apply(this, arguments);
      this.options = this.options || [];
    },

    actions: {
      validateProperty: function validateProperty(changeset, property) {
        return changeset.validate(property);
      }
    }

  });
});