define('client/serializers/transformation-guide', ['exports', 'emberfire/serializers/firebase'], function (exports, _firebase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _firebase.default.extend({
    attrs: {
      pdfs: { embedded: 'always' }
    }
  });
});