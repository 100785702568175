define('client/models/event', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.schema = undefined;
  var schema = exports.schema = {
    name: (0, _attr.default)('string'),
    subtitle: (0, _attr.default)('string'),
    titleColor: (0, _attr.default)('string', { defaultValue: '#000000' }),
    subtitleColor: (0, _attr.default)('string', { defaultValue: '#000000' }),

    venue: (0, _attr.default)('string'),
    address: (0, _attr.default)('string'),
    boothNumber: (0, _attr.default)('string'),
    videoLink: (0, _attr.default)('string'),
    websiteLink: (0, _attr.default)('string'),
    imageLink: (0, _attr.default)('string'),
    floorplan: (0, _attr.default)('string'),

    phone: (0, _attr.default)('string'),
    speaker: (0, _attr.default)('string'),
    topic: (0, _attr.default)('string'),
    date: (0, _attr.default)('string'),
    htmlContent: (0, _attr.default)('string'),
    gpsLocation: (0, _attr.default)('coordinates'),

    isActive: (0, _attr.default)('boolean', { defaultValue: false }),
    isFeatured: (0, _attr.default)('boolean', { defaultValue: false }),
    haveSurvey: (0, _attr.default)('boolean', { defaultValue: false })

  };

  exports.default = _model.default.extend(schema);
});