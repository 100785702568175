define('client/main/dashboard/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin'], function (exports, _ember, _authenticatedRouteMixin) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route;
  exports.default = Route.extend(_authenticatedRouteMixin.default, {
    beforeModel: function beforeModel() {
      this._super.apply(this, arguments);
      return this.transitionTo('transformation-guide-management');
    }
  });
});