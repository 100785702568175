define('client/models/user', ['exports', 'ember-data/model', 'ember-data/attr', 'ember'], function (exports, _model, _attr, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.schema = undefined;
  var computed = _ember.default.computed;
  var schema = exports.schema = {
    name: (0, _attr.default)('string'),
    type: (0, _attr.default)('string'),
    email: (0, _attr.default)('string'),
    isActive: (0, _attr.default)('boolean'),
    password: (0, _attr.default)('string'),
    passwordConfirm: (0, _attr.default)('string'),

    isAdmin: computed.equal('type', 'admin')

  };

  exports.default = _model.default.extend(schema);
});