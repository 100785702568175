define('client/main/components/i-validated-input/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      _get = _ember.default.get;
  exports.default = Component.extend({
    classNames: ['component-i-validated-input', 'form-group'],
    classNameBindings: ['isInvalid:has-error'],
    isDisabled: false,
    isInvalid: computed('changeset.error', 'property', {
      get: function get() {
        return _get(this.get('changeset.error'), this.get('property'));
      }
    }),
    actions: {
      validateProperty: function validateProperty(changeset, property) {
        return changeset.validate(property);
      }
    }

  });
});