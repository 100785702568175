define('client/validations/new-user', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: [(0, _validators.validatePresence)({ presence: true, message: '{description} can\'t be blank' }), (0, _validators.validateLength)({ min: 2 })],
    email: [(0, _validators.validateFormat)({ type: 'email' })],
    password: [(0, _validators.validateLength)({ min: 8 }), (0, _validators.validatePresence)(true)],
    passwordConfirm: (0, _validators.validateConfirmation)({ on: 'password', message: 'Passwords must match' })
  };
});