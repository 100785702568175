define('client/main/components/phone-preview/component', ['exports', 'ember', 'client/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({
    classNames: ['framed', 'with-overlay', 'component-phone-preview'],
    config: _environment.default
  });
});