define('client/main/components/forms/transformation-guide-form/component', ['exports', 'ember', 'client/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      inject = _ember.default.inject,
      Logger = _ember.default.Logger,
      get = _ember.default.get;
  exports.default = Component.extend({
    ajax: inject.service(),
    store: inject.service(),

    notifications: inject.service('notification-messages'),

    showConfirmDelete: false,

    init: function init() {
      this._super.apply(this, arguments);
      this.colors = ['#000000', '#ffffff', '#f0f0f5', '#5f2d74'];
    },

    actions: {
      removeFile: function removeFile(changeset) {
        var _this = this;

        if (get(changeset, 'isValid')) {
          changeset.set('pdfLink', null);
          changeset.save().then(function (saved) {
            return _this.get('ajax').del(_environment.default.apiUrl + '/api/utils/delete-file/transformation_guides/' + saved.id).then(function () {
              return _this.get('notifications').success('File deleted.');
            });
          }).catch(function (err) {
            Logger.error(err);
            _this.get('notifications').error('Error deleting file.');
          });
        } else {
          this.get('notifications').error('Please fix other fields before deleting this file.');
        }
      },
      removePDF: function removePDF(changeset, file) {
        var old = changeset.get('pdfs');
        delete old[file.id];
        var next = Object.keys(old || {}).reduce(function (final, k) {
          final[k] = old[k]; // eslint-disable-line
          return final;
        }, {});
        changeset.set('pdfs', next);
      },
      addPDF: function addPDF(changeset, link) {
        var old = changeset.get('pdfs');
        var next = Object.keys(old || {}).reduce(function (final, k) {
          final[k] = old[k]; // eslint-disable-line
          return final;
        }, {});
        var newPDF = this.get('store').createRecord('pdf', {
          title: '',
          link: link,
          sortOrder: Object.keys(old || {}).length + 1
        });
        next[newPDF.id] = newPDF.toJSON();
        changeset.set('pdfs', next);
      }
    }
  });
});