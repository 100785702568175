define('client/torii-providers/firebase', ['exports', 'emberfire/torii-providers/firebase'], function (exports, _firebase) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _firebase.default.extend({
    fetch: function fetch(data) {
      return data;
    },
    open: function open() {
      var result = this._super.apply(this, arguments);
      return result.then(function (resolved) {
        var formatted = {
          uid: resolved.uid,
          refreshToken: resolved.refreshToken,
          idToken: resolved.Yd,
          email: resolved.email,
          registered: true
        };
        return formatted;
      });
    }
  });
});