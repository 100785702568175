define('client/validations/event', ['exports', 'ember-changeset-validations/validators'], function (exports, _validators) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    name: (0, _validators.validatePresence)({ presence: true, message: '{description} can\'t be blank' }),
    date: (0, _validators.validatePresence)({ presence: true, message: '{description} can\'t be blank' })

  };
});