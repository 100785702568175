define('client/main/base/form-page/controller', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = _ember.default.get,
      Controller = _ember.default.Controller,
      computed = _ember.default.computed,
      inject = _ember.default.inject,
      Logger = _ember.default.Logger;
  var keys = Object.keys;
  exports.default = Controller.extend({
    firebaseUtil: inject.service(),
    session: inject.service(),

    currentRecord: computed.alias('model'),

    actions: {
      save: function save(changeset) {
        var _this = this;

        var snapshot = changeset.snapshot();
        return changeset.cast(keys(this.SCHEMA)).validate().then(function () {
          if (get(changeset, 'isValid')) {
            return changeset.save().then(function () {
              _this.get('notifications').success('Request completed.');
              _this.transitionToRoute(_this.LIST_ROUTE_NAME);
            }).catch(function (err) {
              Logger.error(err);
              _this.get('notifications').error('Error saving model.');
            });
          } else {
            _this.get('notifications').error('Error model is invalid.');
          }
        }).catch(function (err) {
          Logger.error(err);
          changeset.restore(snapshot);
          _this.get('notifications').error('Error saving model.');
        });
      },
      reset: function reset(changeset) {
        return changeset.rollback();
      },
      delete: function _delete(record) {
        var _this2 = this;

        return record.destroyRecord().then(function () {
          return _this2.transitionToRoute(_this2.LIST_ROUTE_NAME);
        }).catch(function (err) {
          return _this2.get('notifications').error(err.payload.error.message || err.message || err);
        });
      }
    }
  });
});