define('client/main/base/list-page/controller', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed,
      inject = _ember.default.inject,
      get = _ember.default.get;
  exports.default = Controller.extend({

    session: inject.service(),
    routing: inject.service('router'),

    sortingBy: ['name'],
    allRecrods: computed.sort('model.listItems.[]', 'sortingBy'),
    shownRecords: computed.filter('allRecrods.@each.{isNew,isDeleted,isSaving}', function (record) {
      return !record.get('isNew') && !record.get('isDeleted');
    }),

    isSelectedRecord: computed('routing.currentRouteName', {
      get: function get() {
        return this.get('routing.currentRouteName') === this.ROUTE_NAME + '.' + this.MODEL_NAME;
      }
    }),

    currentRecord: computed('routing._router.currentState.routerJsState.params', {
      get: function get() {
        var params = this.get('routing._router.currentState.routerJsState.params')[this.ROUTE_NAME + '.' + this.MODEL_NAME];
        var current = params && params.id;
        return current && (this.store.peekRecord(this.MODEL_NAME, current).get('isNew') ? null : current);
      }
    }),

    actions: {
      selectRecord: function selectRecord(rowData) {
        this.transitionToRoute(this.ROUTE_NAME + '.' + this.MODEL_NAME, get(rowData, 'content.id'));
      },
      addRecord: function addRecord() {
        var newRecord = this.store.createRecord(this.MODEL_NAME, { isActive: true });
        this.transitionToRoute(this.ROUTE_NAME + '.' + this.MODEL_NAME, newRecord);
      }
    }
  });
});