define('client/main/components/forms/event-form/component', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      set = _ember.default.set;
  exports.default = Component.extend({
    showConfirmDelete: false,
    init: function init() {
      this._super.apply(this, arguments);
      this.colors = ['#000000', '#ffffff', '#f0f0f5', '#5f2d74'];
    },

    actions: {
      placeSelected: function placeSelected(changeset, locationObject) {
        set(changeset, 'gpsLocation', locationObject.geometry.location.lat() + ', ' + locationObject.geometry.location.lng());
      },
      setDateRange: function setDateRange(changeset, start, end) {
        set(changeset, 'date', (0, _moment.default)(start, 'DD/MM/YYYY').format('DD/MM/YYYY') + ' - ' + (0, _moment.default)(end, 'DD/MM/YYYY').format('DD/MM/YYYY'));
      }
    }
  });
});