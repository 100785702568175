define('client/main/transformation-guide-management/transformation-guide/controller', ['exports', 'client/validations/transformation-guide', 'client/models/transformation-guide', 'client/main/base/form-page/controller', 'ember'], function (exports, _transformationGuide, _transformationGuide2, _controller, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = _ember.default.get,
      Logger = _ember.default.Logger;
  var keys = Object.keys;
  exports.default = _controller.default.extend({
    Validations: _transformationGuide.default,

    actions: {
      save: function save(changeset) {
        var _this = this;

        var snapshot = changeset.snapshot();

        var pdfs = changeset.get('pdfs');
        var first = Object.keys(changeset.get('pdfs') || {})[0];
        changeset.set('pdfLink', first && pdfs[first].link || null);
        changeset.set('pdfTitle', first && pdfs[first].title || null);

        changeset.save();
        return changeset.cast(keys(this.SCHEMA)).validate().then(function () {
          if (get(changeset, 'isValid')) {
            return changeset.save().then(function () {
              _this.get('notifications').success('Request completed.');
              _this.transitionToRoute(_this.LIST_ROUTE_NAME);
            }).catch(function (err) {
              Logger.error(err);
              _this.get('notifications').error('Error saving model.');
            });
          } else {
            _this.get('notifications').error('Error model is invalid.');
          }
        }).catch(function (err) {
          Logger.error(err);
          changeset.restore(snapshot);
          _this.get('notifications').error('Error saving model.');
        });
      }
    },

    init: function init() {
      this._super.apply(this, arguments);
      this.LIST_ROUTE_NAME = 'transformation-guide-management';
      this.SCHEMA = _transformationGuide2.schema;
      this.PUSH_NOTIFICATION_CHAPTER = 'guide';
    }
  });
});