define('client/main/user-management/controller', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      computed = _ember.default.computed,
      inject = _ember.default.inject,
      get = _ember.default.get;
  exports.default = Controller.extend({
    session: inject.service(),
    routing: inject.service('router'),

    selectedUser: computed.equal('routing.currentRouteName', 'user-management.user'),

    syncedUsers: computed.filter('users.@each.{isNew,isDeleted,isSaving}', function (user) {
      return !user.get('isNew') && !user.get('isDeleted');
    }),

    users: computed.filter('model', function (user) {
      var userType = this.get('session.currentUser.type');
      switch (userType) {
        case 'admin':
          return true;
        case 'manager':
          return user.get('type') === 'business-owner';
        default:
          return false;
      }
    }),
    currentUserId: computed('routing._router.currentState.routerJsState.params', {
      get: function get() {
        var params = this.get('routing._router.currentState.routerJsState.params')['user-management.user'];
        var current = params && params.id;
        return current && (this.store.peekRecord('user', current).get('isNew') ? null : current);
      }
    }),
    columns: computed('selectedUser', function () {
      var isSmall = this.get('selectedUser');
      return [{
        width: '40px',
        sortable: false,
        cellComponent: 'list-table/row-toggle',
        breakpoints: isSmall ? ['mobile', 'tablet', 'desktop', 'jumbo'] : ['mobile', 'tablet']
      }, {
        label: 'Name',
        valuePath: 'name',
        sortable: false,
        width: '150px'
      }, {
        label: 'E-mail',
        valuePath: 'email',
        sortable: false,
        breakpoints: isSmall ? [''] : ['tablet', 'desktop', 'jumbo']
      }, {
        label: 'Type',
        valuePath: 'type',
        sortable: false
      }, {
        label: 'Status',
        valuePath: 'isActive',
        sortable: false,
        breakpoints: isSmall ? [''] : ['desktop', 'jumbo'],
        cellComponent: 'list-table/status-field'
      }];
    }),
    actions: {
      selectUser: function selectUser(rowData) {
        var selected = this.store.peekRecord('user', get(rowData, 'content.id'));
        this.transitionToRoute('user-management.user', selected);
      },
      addUser: function addUser() {
        var newUser = this.store.peekRecord('user', 'new') || this.store.createRecord('user', { type: 'admin', isActive: true });
        this.transitionToRoute('user-management.user', newUser);
      }
    }
  });
});