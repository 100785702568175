define('client/main/application/route', ['exports', 'ember', 'ember-simple-auth/mixins/application-route-mixin', 'client/config/environment'], function (exports, _ember, _applicationRouteMixin, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      Logger = _ember.default.Logger,
      testing = _ember.default.testing;
  exports.default = Route.extend(_applicationRouteMixin.default, {
    actions: {
      error: function error(_error) {
        if (_error) {
          Logger.error(_error);
          return this.transitionTo('error');
        }
      }
    },
    sessionInvalidated: function sessionInvalidated() {
      this.transitionTo('loading');
      if (!testing) {
        if (this.get('_isFastBoot')) {
          this.transitionTo(_environment.default.routerRootURL);
        } else {
          window.location.replace(_environment.default.routerRootURL);
        }
      }
    }
  });
});