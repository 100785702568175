define('client/main/event-management/event/controller', ['exports', 'client/validations/event', 'client/models/event', 'client/main/base/form-page/controller'], function (exports, _event, _event2, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _controller.default.extend({
    Validations: _event.default,

    init: function init() {
      this._super.apply(this, arguments);
      this.LIST_ROUTE_NAME = 'event-management';
      this.SCHEMA = _event2.schema;
      this.PUSH_NOTIFICATION_CHAPTER = 'event';
    }
  });
});