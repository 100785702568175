define('client/models/pdf', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.schema = undefined;
  var schema = exports.schema = {
    link: (0, _attr.default)('string'),
    title: (0, _attr.default)('string'),
    sortOrder: (0, _attr.default)('number')
  };

  exports.default = _model.default.extend(schema);
});