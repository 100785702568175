define('client/models/product', ['exports', 'ember-data/model', 'ember-data/attr'], function (exports, _model, _attr) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.schema = undefined;
  var schema = exports.schema = {
    name: (0, _attr.default)('string'),
    subtitle: (0, _attr.default)('string'),
    titleColor: (0, _attr.default)('string', { defaultValue: '#000000' }),
    subtitleColor: (0, _attr.default)('string', { defaultValue: '#000000' }),

    description: (0, _attr.default)('string'),
    pdfTitle: (0, _attr.default)('string'),
    htmlContent: (0, _attr.default)('string'),
    sortOrder: (0, _attr.default)('number'),
    videoLink: (0, _attr.default)('string'),
    pdfLink: (0, _attr.default)('string'),
    imageLink: (0, _attr.default)('string'),

    isFeatured: (0, _attr.default)('boolean', { defaultValue: false }),
    isActive: (0, _attr.default)('boolean'),
    pdfs: (0, _attr.default)('just-json', { defaultValue: {} })
  };

  exports.default = _model.default.extend(schema);
});