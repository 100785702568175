define('client/mixins/management-only', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = _ember.default.Mixin,
      inject = _ember.default.inject;
  exports.default = Mixin.create({
    session: inject.service(),
    beforeModel: function beforeModel() {
      var role = this.get('session.currentUser.type');
      if (role !== 'admin' && role !== 'manager') {
        this.transitionTo('not-found-redirect');
      }
    }
  });
});