define('client/main/components/pdf-list/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _ember.default.Component.extend({
    pdfsFormatted: _ember.default.computed('pdfs.@each.title', 'pdfs', {
      get: function get() {
        var pdfs = this.get('pdfs');
        return pdfs && Object.keys(pdfs).map(function (k) {
          var pdf = pdfs[k];
          pdf.id = k;
          return pdf;
        }).sortBy('sortOrder');
      }
    })
  });
});