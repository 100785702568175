define("client/initializers/admin_load", ["exports", "ember"], function (exports, _ember) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var $ = _ember.default.$,
      Component = _ember.default.Component;


  Component.reopen({
    didRender: function didRender() {
      if (!$.AdminLTE.options.loadState) $.AdminLTE.init();

      $.AdminLTE.initRender();
      this._super();
    }
  });

  exports.default = {
    name: "adminLTE-theme",
    initialize: function initialize() {}
  };
});