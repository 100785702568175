define('client/mixins/admin-only', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Mixin = _ember.default.Mixin,
      inject = _ember.default.inject;
  exports.default = Mixin.create({
    session: inject.service(),
    beforeModel: function beforeModel() {
      if (this.get('session.currentUser.type') !== 'admin') {
        this.transitionTo('not-found-redirect');
      }
    }
  });
});