define('client/locales/en/translations', ['exports'], function (exports) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = {
    app: {
      title: '<b>Philips</b> DPS',
      shortTitle: '<b>P</b>D'
    },
    days: {
      mon: 'Monday',
      tue: 'Tuesday',
      wed: 'Wednesday',
      thu: 'Thursday',
      fri: 'Friday',
      sat: 'Saturday',
      sun: 'Sunday'
    },
    fields: {
      haveSurvey: 'Have survey on the bottom',
      subtitle: 'Subtitle',
      videoLink: 'Video URL',
      pdfTitle: 'PDF Title',
      pdfLink: 'Linked PDF File',
      sortOrder: 'Sort Order',
      venue: 'Venue',
      email: 'E-mail',
      type: 'Type',
      status: 'Status',
      active: 'Active',
      inactive: 'Inactive',
      address: 'Address',
      postalCode: 'Postal code',
      city: 'City',
      country: 'Country',
      name: 'Full Name',
      password: 'Password',
      passwordConfirm: 'Confirm password',
      websiteLink: 'Website URL',
      phoneNumber: 'Phone number',
      validUntil: 'Valid until',
      product: 'Product',
      productName: 'Product Name',
      eventName: 'Event Name',
      chapterName: 'Chapter Name',
      gpsLocation: 'GPS Location',
      description: 'Description',
      shareLink: 'Share Link',
      htmlContent: 'HTML Content',
      roadshow: 'Roadshow',
      boothNumber: 'Philips Booth Number',
      phone: 'Phone Number',
      topic: 'Event Topic',
      speaker: 'Speaker',
      date: 'Date',
      featured: 'Featured',
      titleColor: 'Title Color',
      subtitleColor: 'Subtitle Color'
    },
    actions: {
      save: 'Save',
      reset: 'Reset',
      delete: 'Delete',
      addUser: 'Add a user',
      addBusiness: 'Add a chapter',
      'add-product': 'Add a product',
      'add-event': 'Add an event',
      'add-transformation-guide': 'Add a chapter',
      createPush: 'Create notification'
    },
    layout: {
      header: {
        language: {
          choose: 'Choose a language',
          en: 'English',
          nl: 'Dutch'
        },
        'sign-out': 'Logout'
      },
      footer: {
        copy: '<strong>Copyright &copy; 2017 Glamorous Goat</strong> All rights  reserved. ',
        version: '<b>Version</b> 0.0.1'
      }
    },
    'user-management-page': {
      title: 'User management',
      subtitle: 'For user management tasks',
      sidebar: 'User Management',
      edit: {
        title: 'Modify user'
      },
      new: {
        title: 'Create a new user'
      }
    },
    'transformation-guide-management-page': {
      title: 'Transformation Guide management',
      subtitle: 'add/modify chapters',
      sidebar: 'Transformation Guide',
      edit: {
        title: 'Modify Transformation Guide'
      },
      new: {
        title: 'Create a new Transformation Guide'
      }
    },
    'transformation-guide-page': {
      title: 'Transformation guides',
      subtitle: 'add/modify everything regarding the transformation guide',
      sidebar: 'Transformation Guides',
      edit: {
        title: 'Modify a chapter'
      },
      new: {
        title: 'Create a new chapter'
      }
    },
    'product-management-page': {
      title: 'Product management',
      subtitle: 'add/modify everything regarding your product',
      sidebar: 'Product Management',
      edit: {
        title: 'Modify product'
      },
      new: {
        title: 'Create a new product'
      }
    },
    'product-page': {
      title: 'Product management',
      subtitle: 'add/modify products',
      sidebar: 'Products',
      edit: {
        title: 'Modify product'
      },
      new: {
        title: 'Create a new product'
      }
    },
    'event-management-page': {
      title: 'Event management',
      subtitle: 'add/modify events',
      sidebar: 'Events',
      edit: {
        title: 'Modify Event'
      },
      new: {
        title: 'Create a new Event'
      }
    },
    'event-page': {
      title: 'Event management',
      subtitle: 'add/modify products',
      sidebar: 'Products',
      edit: {
        title: 'Modify event'
      },
      new: {
        title: 'Create a new event'
      }
    },
    'dashboard-page': {
      title: 'Welcome to the dashboard!',
      sidebar: 'Dashboard'
    },
    'error-page': {
      title: 'Oops! Something went wrong.',
      subtitle: 'We will work on fixing that right away. Meanwhile, you may',
      'dashboard-link': 'return to the dashboard'
    },
    'page-not-found': {
      title: 'Oops! Page not found.',
      subtitle: 'We could not find the page you were looking for. Meanwhile, you may'
    },
    'login-page': {
      'forgot-password': 'I forgot my password',
      title: 'Sign in to start your session',
      'sign-in': 'Sign in'
    },
    'reset-password-page': {
      title: 'Enter your E-mail to request a password reset.',
      submit: 'Submit'
    },
    notifications: {
      'password-reset-success': 'Your password reset request has been sent.'
    },
    'error-messages': {
      required: '{{field}} is required.'
    }
  };
});