define('client/transforms/coordinates', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Transform = _emberData.default.Transform;
  var isEmpty = _ember.default.isEmpty;
  exports.default = Transform.extend({
    deserialize: function deserialize(serialized) {
      return serialized && serialized.lat + ', ' + serialized.lng;
    },
    serialize: function serialize(deserialized) {
      if (isEmpty(deserialized)) {
        return null;
      }
      var coords = deserialized.split(',');
      return {
        lat: coords[0] && coords[0].trim(),
        lng: coords[1] && coords[1].trim()
      };
    }
  });
});