define('client/mixins/table-common', ['exports', 'ember', 'ember-light-table', 'ember-concurrency'], function (exports, _ember, _emberLightTable, _emberConcurrency) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = _ember.default.inject,
      A = _ember.default.A,
      computed = _ember.default.computed,
      Mixin = _ember.default.Mixin,
      observer = _ember.default.observer;
  exports.default = Mixin.create({
    store: inject.service(),

    page: 1,
    limit: 8,
    dir: 'asc',
    sort: null,

    enableSync: true,

    data: null,
    meta: null,
    columns: null,
    table: null,

    model: A([]),
    totalPages: computed('data.length', 'limit', {
      get: function get() {
        var limit = this.get('limit');
        return Math.ceil(this.get('data.length') / limit);
      }
    }),
    updateTable: observer('data.[]', function () {
      // eslint-disable-line ember/no-observers
      this.get('fetchRecords').perform();
    }).on('init'),
    fetchRecords: (0, _emberConcurrency.task)(regeneratorRuntime.mark(function _callee() {
      var page, limit;
      return regeneratorRuntime.wrap(function _callee$(_context) {
        while (1) {
          switch (_context.prev = _context.next) {
            case 0:
              page = this.get('page');
              limit = this.get('limit');

              this.get('model').clear();
              _context.next = 5;
              return this.get('model').pushObjects(this.get('data').slice((page - 1) * limit, page * limit));

            case 5:
            case 'end':
              return _context.stop();
          }
        }
      }, _callee, this);
    })).restartable(),
    init: function init() {
      this._super.apply(this, arguments);
      this.get('model').clear();

      var table = new _emberLightTable.default(this.get('columns'), this.get('model'), { enableSync: this.get('enableSync') });
      var sortColumn = table.get('allColumns').findBy('valuePath', this.get('sort'));

      // Setup initial sort column
      if (sortColumn) {
        sortColumn.set('sorted', true);
      }

      this.set('table', table);
      this.get('fetchRecords').perform();
    },


    actions: {
      onColumnClick: function onColumnClick(column) {
        if (column.sorted) {
          this.setProperties({
            dir: column.ascending ? 'asc' : 'desc',
            sort: column.get('valuePath')
          });
        }
      }
    }
  });
});