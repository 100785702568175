define('client/transforms/date-range', ['exports', 'ember-data', 'ember'], function (exports, _emberData, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Transform = _emberData.default.Transform;
  var isEmpty = _ember.default.isEmpty;
  exports.default = Transform.extend({
    deserialize: function deserialize(serialized) {
      if (isEmpty(serialized)) {
        return {
          start: null,
          stop: null
        };
      }
    },
    serialize: function serialize(deserialized) {
      if (isEmpty(deserialized)) {
        return null;
      }
    }
  });
});