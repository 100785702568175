define('client/main/user-management/user/controller', ['exports', 'ember', 'client/validations/user', 'client/validations/new-user', 'client/config/environment', 'client/models/user'], function (exports, _ember, _user, _newUser, _environment, _user2) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var get = _ember.default.get,
      Controller = _ember.default.Controller,
      computed = _ember.default.computed,
      inject = _ember.default.inject,
      merge = _ember.default.merge;
  var keys = Object.keys;
  exports.default = Controller.extend({
    ajax: inject.service(),
    UserValidations: _user.default,
    NewUserValidations: _newUser.default,
    user: computed.alias('model'),
    actions: {
      save: function save(changeset) {
        var _this = this;

        var snapshot = changeset.snapshot();
        var tempUser = this.get('user');

        return changeset.cast(keys(_user2.schema)).validate().then(function () {
          if (get(changeset, 'isValid')) {
            if (tempUser.get('isNew')) {
              var values = { type: 'admin', isActive: true };
              merge(values, get(changeset, 'change'));
              delete values.passwordConfirm;
              changeset.set('isSaving', true);
              _this.get('ajax').post(_environment.default.apiUrl + '/api/users', { data: values }).then(function () {
                tempUser.unloadRecord();
                changeset.set('isSaving', false);
                _this.transitionToRoute('user-management');
              }).catch(function (err) {
                changeset.set('isSaving', false);
                return err && _this.get('notifications').error(err.payload && err.payload.error && err.payload.error.message || err.message || err);
              });
            } else {
              return changeset.save().then(function () {
                _this.transitionToRoute('user-management');
              });
            }
          }
        }).catch(function () {
          changeset.restore(snapshot);
        });
      },
      reset: function reset(changeset) {
        return changeset.rollback();
      },
      delete: function _delete(record) {
        var _this2 = this;

        return this.get('ajax').del(_environment.default.apiUrl + '/api/users/' + record.id).then(function () {
          return record.destroyRecord();
        }).then(function () {
          return _this2.transitionToRoute('user-management');
        }).catch(function (err) {
          return _this2.get('notifications').error(err.payload.error.message || err.message || err);
        });
      }
    }
  });
});