define('client/main/components/quill-editor/component', ['exports', 'ember', 'client/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  function _classCallCheck(instance, Constructor) {
    if (!(instance instanceof Constructor)) {
      throw new TypeError("Cannot call a class as a function");
    }
  }

  var _createClass = function () {
    function defineProperties(target, props) {
      for (var i = 0; i < props.length; i++) {
        var descriptor = props[i];
        descriptor.enumerable = descriptor.enumerable || false;
        descriptor.configurable = true;
        if ("value" in descriptor) descriptor.writable = true;
        Object.defineProperty(target, descriptor.key, descriptor);
      }
    }

    return function (Constructor, protoProps, staticProps) {
      if (protoProps) defineProperties(Constructor.prototype, protoProps);
      if (staticProps) defineProperties(Constructor, staticProps);
      return Constructor;
    };
  }();

  function _possibleConstructorReturn(self, call) {
    if (!self) {
      throw new ReferenceError("this hasn't been initialised - super() hasn't been called");
    }

    return call && (typeof call === "object" || typeof call === "function") ? call : self;
  }

  var _get = function get(object, property, receiver) {
    if (object === null) object = Function.prototype;
    var desc = Object.getOwnPropertyDescriptor(object, property);

    if (desc === undefined) {
      var parent = Object.getPrototypeOf(object);

      if (parent === null) {
        return undefined;
      } else {
        return get(parent, property, receiver);
      }
    } else if ("value" in desc) {
      return desc.value;
    } else {
      var getter = desc.get;

      if (getter === undefined) {
        return undefined;
      }

      return getter.call(receiver);
    }
  };

  function _inherits(subClass, superClass) {
    if (typeof superClass !== "function" && superClass !== null) {
      throw new TypeError("Super expression must either be null or a function, not " + typeof superClass);
    }

    subClass.prototype = Object.create(superClass && superClass.prototype, {
      constructor: {
        value: subClass,
        enumerable: false,
        writable: true,
        configurable: true
      }
    });
    if (superClass) Object.setPrototypeOf ? Object.setPrototypeOf(subClass, superClass) : subClass.__proto__ = superClass;
  }

  var run = _ember.default.run,
      get = _ember.default.get,
      set = _ember.default.set,
      Component = _ember.default.Component,
      computed = _ember.default.computed,
      inject = _ember.default.inject;


  function decodeHTML(encodedString) {
    var textArea = document.createElement('textarea');
    textArea.innerHTML = encodedString;
    return textArea.value;
  }

  exports.default = Component.extend({
    ajax: inject.service(),
    classNames: ['component-quill-editor'],
    attributeBindings: ['initialStyle:style'],

    minHeight: '300px',
    body: '',
    initialStyle: computed('minHeight', {
      get: function get() {
        return _ember.default.String.htmlSafe('min-height: ' + this.get('minHeight'));
      }
    }),
    didInsertElement: function didInsertElement() {
      var _this3 = this;

      var toolbarOptions = [[{
        size: ['small', false, 'large', 'huge']
      }], // custom dropdown
      ['bold', 'italic', 'underline', 'strike'], // toggled buttons
      [{
        list: 'ordered'
      }, {
        list: 'bullet'
      }], [{
        color: []
      }], // dropdown with defaults from theme
      [{
        align: []
      }], ['link', 'image', 'video']];
      var container = this.$('.editor')[0];
      var self = this;
      run.next(function () {
        var Inline = Quill.import('formats/video');

        var VideoBlot = function (_Inline) {
          _inherits(VideoBlot, _Inline);

          function VideoBlot() {
            _classCallCheck(this, VideoBlot);

            return _possibleConstructorReturn(this, (VideoBlot.__proto__ || Object.getPrototypeOf(VideoBlot)).apply(this, arguments));
          }

          _createClass(VideoBlot, null, [{
            key: 'create',
            value: function create(val) {
              var node = _get(VideoBlot.__proto__ || Object.getPrototypeOf(VideoBlot), 'create', this).call(this, val);
              node.textContent = val;
              return node;
            }
          }, {
            key: 'formats',
            value: function formats() {
              return true;
            }
          }]);

          return VideoBlot;
        }(Inline);

        VideoBlot.blotName = 'video';
        VideoBlot.tagName = 'video';

        Quill.register(VideoBlot);

        var ImageFormat = Quill.import('formats/image');

        var ImageBlot = function (_ImageFormat) {
          _inherits(ImageBlot, _ImageFormat);

          function ImageBlot() {
            _classCallCheck(this, ImageBlot);

            return _possibleConstructorReturn(this, (ImageBlot.__proto__ || Object.getPrototypeOf(ImageBlot)).apply(this, arguments));
          }

          _createClass(ImageBlot, null, [{
            key: 'create',
            value: function create(val) {
              var node = _get(ImageBlot.__proto__ || Object.getPrototypeOf(ImageBlot), 'create', this).call(this, val);
              node.setAttribute('width', 350);

              var image = new Image();
              image.src = val;
              image.onload = function () {
                node.setAttribute('originalWidth', image.width);
                node.setAttribute('originalHeight', image.height);
                self.textChange();
              };

              return node;
            }
          }]);

          return ImageBlot;
        }(ImageFormat);

        Quill.register(ImageBlot);

        var quill = new Quill(container, {
          modules: {
            toolbar: toolbarOptions,
            imageResize: {}

          },
          readOnly: get(_this3, 'isReadOnly'),
          theme: 'snow'
        });
        var initialText = get(_this3, 'body') || '';

        quill.clipboard.dangerouslyPasteHTML(initialText);

        function uploadImageHandler() {
          var input = document.createElement('input');
          input.setAttribute('type', 'file');
          input.setAttribute('accept', 'image/*');

          input.click();

          input.onchange = function () {
            var file = input.files[0];

            // Only accept image files.
            if (/^image\//.test(file.type)) {
              var fd = new FormData();
              fd.append('image', file);
              fd.append('path', self.imagesLocationPrefix);

              self.get('ajax').request(_environment.default.apiUrl + '/api/utils/upload-photo', {
                data: fd,
                processData: false,
                contentType: false,
                method: 'POST'
              }).then(function (uploaded) {
                // Push the image url to rich editor.
                var range = quill.getSelection();
                quill.insertEmbed(range.index, 'image', uploaded.path);
              }).catch(function () {});
            } else {
              alert('You can only upload images!'); // eslint-disable-line no-alert
            }
          };
        }
        quill.getModule('toolbar').addHandler('image', function () {
          uploadImageHandler();
        });

        quill.on('text-change', run.bind(_this3, 'textChange'));
        set(_this3, 'editor', quill);
      });
    },
    textChange: function textChange() {
      var quill = get(this, 'editor');
      this.onChange(decodeHTML(quill.root.innerHTML));
    }
  });
});