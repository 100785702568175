define('client/services/ajax', ['exports', 'ember', 'ember-ajax/services/ajax'], function (exports, _ember, _ajax) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed,
      inject = _ember.default.inject;
  exports.default = _ajax.default.extend({
    trustedHosts: [/\.example\./, 'localhost'],
    session: inject.service(),
    headers: computed('session.data.authenticated', {
      get: function get() {
        var headers = {};
        var authData = this.get('session.data.authenticated');
        if (authData) {
          headers.Authorization = JSON.stringify({
            uid: authData.uid,
            idToken: authData.idToken,
            refreshToken: authData.refreshToken
          });
        }
        return headers;
      }
    })
  });
});