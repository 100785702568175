define('client/main/login/controller', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Controller = _ember.default.Controller,
      inject = _ember.default.inject;
  exports.default = Controller.extend({ // eslint-disable-line ember/alias-model-in-controller
    session: inject.service(),
    processing: false,
    actions: {
      signIn: function signIn() {
        this.set('processing', true);
        this.set('errorMessage', '');
        var email = this.get('email');
        var password = this.get('password');
        var opt = { provider: 'password', email: email, password: password };
        this.get('session').authenticate('authenticator:torii', 'firebase', opt).then(this.signInSuccess.bind(this)).catch(this.signInFailed.bind(this));
      }
    },
    signInSuccess: function signInSuccess() {
      this.set('processing', false);
    },
    signInFailed: function signInFailed(err) {
      this.set('errorMessage', err);
      this.set('processing', false);
    }
  });
});