define('client/main/components/push-notification-dialog/component', ['exports', 'ember', 'client/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({
    ajax: _ember.default.inject.service(),
    notifications: _ember.default.inject.service('notification-messages'),

    classNames: ['component-confirm-dialog'],
    processing: false,
    titleText: 'Attention!',
    bodyText: "Are you sure? This operation can't be undone.",
    submitText: 'Send',
    cancelText: 'Close',
    actions: {
      close: function close() {
        this.onClose();
      },
      save: function save() {
        var _this = this;

        this.set('processing', true);

        this.get('ajax').post(_environment.default.apiUrl + '/api/notifications/push', {
          data: {
            channel: this.channel || 'general',
            chapter: this.chapter || 'all',
            key: this.id || 'all',
            title: this.title,
            description: this.description
          },
          contentType: 'application/json; charset=utf-8'
        }).then(function () {
          this.set('title', '');
          this.set('description', '');
          this.get('notifications').success('Notification sent.');
          this.onClose();
        }).catch(function () {
          return _this.onClose();
        });
      }
    }
  });
});