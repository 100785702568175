define('client/main/event-management/controller', ['exports', 'ember', 'client/main/base/list-page/controller', 'moment'], function (exports, _ember, _controller, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _controller.default.extend({
    allRecrods: computed.sort('model.listItems.[]', function (eventA, eventB) {
      if (eventA.get('isNew')) {
        return 1;
      }
      if (eventB.get('isNew')) {
        return -1;
      }
      var startA = eventA.get('date').split('-')[0];
      var startB = eventB.get('date').split('-')[0];
      if ((0, _moment.default)(startA, 'DD/MM/YYYY').isAfter((0, _moment.default)(startB, 'DD/MM/YYYY'))) {
        return -1;
      }
      if ((0, _moment.default)(startB, 'DD/MM/YYYY').isAfter((0, _moment.default)(startA, 'DD/MM/YYYY'))) {
        return 1;
      }
      return 0;
    }),
    columns: computed('isSelectedRecord', function () {
      var isSmall = this.get('isSelectedRecord');

      return [{
        width: '40px',
        sortable: false,
        cellComponent: 'list-table/row-toggle',
        breakpoints: isSmall ? ['mobile', 'tablet', 'desktop', 'jumbo'] : ['mobile']
      }, {
        label: 'Event Name',
        valuePath: 'name',
        sortable: false
      }, {
        label: 'Status',
        valuePath: 'isActive',
        breakpoints: isSmall ? [''] : ['tablet', 'desktop', 'jumbo'],
        cellComponent: 'list-table/status-field',
        sortable: false
      }];
    })

  });
});