define('client/main/components/forms/user-form/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      inject = _ember.default.inject,
      computed = _ember.default.computed;
  exports.default = Component.extend({
    session: inject.service(),
    showConfirmDelete: false,

    types: computed('session.currentUser.type', {
      get: function get() {
        return ['admin'];
      }
    }),

    init: function init() {
      this._super();
      this.typeNames = {
        admin: 'Administrator',
        manager: 'Manager',
        'business-owner': 'Business Owner',
        'app-user': 'Application User'
      };
    }
  });
});