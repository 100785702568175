define('client/router', ['exports', 'ember', 'client/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });


  var Router = _ember.default.Router.extend({
    location: _environment.default.locationType,
    rootURL: _environment.default.routerRootURL
  });

  Router.map(function () {
    // eslint-disable-line array-callback-return
    this.route('dashboard', { path: '/' });

    this.route('login');
    this.route('reset-password');

    this.route('transformation-guide-management', function () {
      this.route('transformation-guide', { path: '/:id' });
    });
    this.route('user-management', function () {
      this.route('user', { path: '/:id' });
    });
    this.route('event-management', function () {
      this.route('event', { path: '/:id' });
    });
    this.route('product-management', function () {
      this.route('product', { path: '/:id' });
    });

    this.route('error');
    this.route('not-found', { path: '/*path' });
    this.route('not-found-redirect');
  });

  exports.default = Router;
});