define('client/services/firebase-ui', ['exports', 'emberfire-utils/services/firebase-ui'], function (exports, _firebaseUi) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  Object.defineProperty(exports, 'default', {
    enumerable: true,
    get: function () {
      return _firebaseUi.default;
    }
  });
});