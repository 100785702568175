define('client/main/user-management/route', ['exports', 'ember', 'ember-simple-auth/mixins/authenticated-route-mixin', 'client/mixins/management-only'], function (exports, _ember, _authenticatedRouteMixin, _managementOnly) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route,
      inject = _ember.default.inject;
  exports.default = Route.extend(_authenticatedRouteMixin.default, _managementOnly.default, {
    session: inject.service(),
    model: function model() {
      return this.store.findAll('user', { reload: true });
    }
  });
});