define("client/initializers/current-path", ["exports"], function (exports) {
  "use strict";

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.initialize = initialize;
  function initialize(app) {
    if (app.injection) {
      app.injection("component:nav-breadcrumb", "appRoute", "route:application");
      app.injection("component:main-sidebar", "appRoute", "route:application");
    } else {
      app.inject("component:nav-breadcrumb", "appRoute", "route:application");
      app.inject("component:main-sidebar", "appRoute", "route:application");
    }
  }

  exports.default = {
    name: 'current-path',
    initialize: initialize
  };
});