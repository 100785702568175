define('client/adapters/user', ['exports', 'client/adapters/application'], function (exports, _application) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  exports.default = _application.default.extend({
    generateIdForRecord: function generateIdForRecord() {
      return 'new';
    }
  });
});