define('client/main/transformation-guide-management/controller', ['exports', 'ember', 'client/main/base/list-page/controller'], function (exports, _ember, _controller) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var computed = _ember.default.computed;
  exports.default = _controller.default.extend({
    sortingBy: ['sortOrder'],

    columns: computed('isSelectedRecord', function () {
      var isSmall = this.get('isSelectedRecord');

      return [{
        width: '40px',
        sortable: false,
        cellComponent: 'list-table/row-toggle',
        breakpoints: isSmall ? ['mobile', 'tablet', 'desktop', 'jumbo'] : ['mobile']
      }, {
        label: 'Chapter Name',
        valuePath: 'name',
        sortable: false
      }, {
        label: 'Sort Order',
        valuePath: 'sortOrder',
        sortable: false,
        breakpoints: isSmall ? [''] : ['tablet', 'desktop', 'jumbo']
      }, {
        label: 'Status',
        valuePath: 'isActive',
        sortable: false,
        breakpoints: isSmall ? [''] : ['tablet', 'desktop', 'jumbo'],
        cellComponent: 'list-table/status-field'
      }];
    })

  });
});