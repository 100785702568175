define('client/main/application/controller', ['exports', 'ember', 'client/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = _ember.default.inject,
      Controller = _ember.default.Controller,
      computed = _ember.default.computed;
  exports.default = Controller.extend({ // eslint-disable-line ember/alias-model-in-controller

    session: inject.service(),
    routing: inject.service('router'),
    config: _environment.default,

    isSessionAuthenticated: computed.and('session.{isAuthenticated,currentUser}'),
    currentRoute: computed.alias('routing.currentRouteName'),

    locales: computed('i18n.{locale,locales}', function () {
      var i18n = this.get('i18n');
      return this.get('i18n.locales').map(function (loc) {
        return {
          id: loc,
          text: i18n.t('layout.header.language.' + loc)
        };
      });
    }),

    actions: {
      signOut: function signOut() {
        this.get('session').invalidate();
      },
      setLocale: function setLocale(loc) {
        this.set('i18n.locale', loc);
      }
    },
    init: function init() {
      this._super.apply(this, arguments);
      this.get('notifications').setDefaultAutoClear(true);
    }
  });
});