define('client/main/components/list-table/component', ['exports', 'ember', 'client/mixins/table-common'], function (exports, _ember, _tableCommon) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      observer = _ember.default.observer,
      get = _ember.default.get,
      set = _ember.default.set,
      isNone = _ember.default.isNone;
  exports.default = Component.extend(_tableCommon.default, {

    columns: null,
    setSelected: observer('selected', function () {
      var _this = this;

      // eslint-disable-line ember/no-observers
      this.get('table.rows').forEach(function (row) {
        if (isNone(_this.get('selected'))) {
          _this.get('table.selectedRows').setEach('selected', false);
          return;
        }
        if (get(row, 'content.id') === _this.get('selected')) {
          set(row, 'selected', true);
        }
      });
    }).on('init'),
    resizeTable: observer('columns', function () {
      // eslint-disable-line ember/no-observers
      this.get('table').setColumns(this.get('columns'));
    }),

    actions: {
      onAfterResponsiveChange: function onAfterResponsiveChange(matches) {
        if (matches.indexOf('jumbo') > -1) {
          this.get('table.expandedRows').setEach('expanded', false);
        }
      },
      onSelect: function onSelect(row) {
        if (get(row, 'content.id') === this.get('selected')) {
          set(row, 'selected', true);
          return;
        }
        this.onSelectRow(row);
      },
      setPage: function setPage(page) {
        var totalPages = this.get('totalPages');
        var currPage = this.get('page');

        if (page < 1 || page > totalPages || page === currPage) {
          return;
        }
        this.set('page', page);
        this.get('fetchRecords').perform();
      }
    }
  });
});