define('client/main/components/confirm-dialog/component', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component;
  exports.default = Component.extend({
    classNames: ['component-confirm-dialog'],
    processing: false,
    titleText: 'Attention!',
    bodyText: "Are you sure? This operation can't be undone.",
    submitText: 'Save',
    cancelText: 'Close',
    actions: {
      close: function close() {
        this.onClose();
      },
      save: function save() {
        var _this = this;

        this.set('processing', true);
        this.onSave().then(function () {
          _this.onClose();
        }).catch(function () {
          _this.onClose();
        });
      }
    }
  });
});