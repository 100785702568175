define('client/main/components/i-validated-date/component', ['exports', 'ember', 'moment'], function (exports, _ember, _moment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Component = _ember.default.Component,
      computed = _ember.default.computed,
      _get = _ember.default.get,
      set = _ember.default.set;
  exports.default = Component.extend({
    classNames: ['component-i-validated-input', 'form-group'],
    classNameBindings: ['isInvalid:has-error'],
    isDisabled: false,
    isInvalid: computed('changeset.error', 'property', {
      get: function get() {
        return _get(this.get('changeset.error'), this.get('property'));
      }
    }),
    currentDate: computed({
      get: function get() {
        return (0, _moment.default)(_get(this.get('changeset'), this.get('property')) * 1000).format('MM/DD/YYYY');
      },
      set: function set(k, v) {
        return v;
      }
    }),
    actions: {
      changeDate: function changeDate(changeset, property, date) {
        set(changeset, property, (0, _moment.default)(date).add(1, 'days').subtract(1, 'seconds').unix());
      }
    }
  });
});