define('client/main/base/form-page/route', ['exports', 'ember'], function (exports, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var Route = _ember.default.Route;
  exports.default = Route.extend({
    model: function model(params) {
      var model = this.store.peekRecord(this.MODEL_NAME, params.id);
      if (!model) {
        return this.transitionTo('not-found-redirect');
      }
      return model;
    },
    setupController: function setupController(controller) {
      this._super.apply(this, arguments);
      controller.set('MODEL_NAME', this.MODEL_NAME);
      controller.set('ROUTE_NAME', this.ROUTE_NAME);
    }
  });
});