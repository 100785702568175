define('client/authenticators/torii', ['exports', 'ember-simple-auth/authenticators/torii', 'ember'], function (exports, _torii, _ember) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });
  var inject = _ember.default.inject,
      get = _ember.default.get;
  exports.default = _torii.default.extend({
    torii: inject.service(),
    store: inject.service(),
    session: inject.service(),

    injectCurrentUserInSession: function injectCurrentUserInSession(sessionData) {
      var _this = this;

      return this.get('store').findRecord('user', get(sessionData, 'uid')).then(function (userData) {
        _this.set('session.currentUser', userData);
      });
    },
    restore: function restore() {
      var restored = this._super.apply(this, arguments);
      return restored.then(this.injectCurrentUserInSession.bind(this)).then(function () {
        return restored;
      });
    },
    authenticate: function authenticate() {
      var authenticated = this._super.apply(this, arguments);
      return authenticated.then(this.injectCurrentUserInSession.bind(this)).then(function () {
        return authenticated;
      });
    }
  });
});