define('client/main/components/image-upload/component', ['exports', 'ember', 'client/config/environment'], function (exports, _ember, _environment) {
  'use strict';

  Object.defineProperty(exports, "__esModule", {
    value: true
  });

  var _slicedToArray = function () {
    function sliceIterator(arr, i) {
      var _arr = [];
      var _n = true;
      var _d = false;
      var _e = undefined;

      try {
        for (var _i = arr[Symbol.iterator](), _s; !(_n = (_s = _i.next()).done); _n = true) {
          _arr.push(_s.value);

          if (i && _arr.length === i) break;
        }
      } catch (err) {
        _d = true;
        _e = err;
      } finally {
        try {
          if (!_n && _i["return"]) _i["return"]();
        } finally {
          if (_d) throw _e;
        }
      }

      return _arr;
    }

    return function (arr, i) {
      if (Array.isArray(arr)) {
        return arr;
      } else if (Symbol.iterator in Object(arr)) {
        return sliceIterator(arr, i);
      } else {
        throw new TypeError("Invalid attempt to destructure non-iterable instance");
      }
    };
  }();

  var Component = _ember.default.Component,
      inject = _ember.default.inject,
      computed = _ember.default.computed;
  exports.default = Component.extend({
    ajax: inject.service(),
    firebaseUtil: inject.service(),
    classNames: ['form-group', 'component-upload-base'],
    labelText: 'Photo',
    uploadProgress: 0,
    locationPrefix: 'images',
    skipResize: false,
    imageUrl: computed('value', {
      get: function get() {
        return this.get('value') || _environment.default.rootURL + 'images/image-placeholder.png';
      }
    }),
    actions: {
      upload: function upload(_ref) {
        var _this = this;

        var _ref2 = _slicedToArray(_ref, 1),
            photo = _ref2[0];

        var fd = new FormData();
        fd.append('image', photo);
        fd.append('path', this.get('locationPrefix'));

        if (this.get('skipResize')) {
          this.get('firebaseUtil').uploadFile(this.get('locationPrefix') + '/' + photo.name + '_' + new Date().getTime(), photo, {}, this.onStateChange.bind(this)).then(function (downloadURL) {
            _this.onUpload(downloadURL);
            _this.set('uploadProgress', 0);
          }).catch(function () {
            _this.set('uploadProgress', 0);
          });
        } else {
          this.set('uploadProgress', 50);
          this.get('ajax').request(_environment.default.apiUrl + '/api/utils/upload-photo', {
            data: fd,
            processData: false,
            contentType: false,
            method: 'POST'
          }).then(function (uploaded) {
            _this.onUpload(uploaded.path);
            _this.set('uploadProgress', 0);
          }).catch(function () {
            _this.set('uploadProgress', 0);
          });
        }
      }
    },

    onStateChange: function onStateChange(snapshot) {
      var progress = snapshot.bytesTransferred / snapshot.totalBytes * 100;
      this.set('uploadProgress', progress);
    }
  });
});